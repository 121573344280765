<template>
  <div class="card__wrapper">
    <div class="card__img--wrapper">
      <!-- <img class="card__img" v-bind:src="cardData.image" alt=""> -->
      <img class="card__img" :src="cardData.logo_path" alt="assets/images/Haefele_menu1.png" />
    </div>
    <div class="card__content">
      <div v-if="!isVisitor" class="user_count">
        <div class="counter_number">{{cardData.user_count}}</div>
        <img class="card__img" src="~/assets/images/user_counter.png" />
      </div>
      <div class="card__content--wrapper">
        <div class="card__header">
          <p class="card__counter">Thema {{ index }}/12</p>
          <h2 class="card__heading">{{ cardData.title}}</h2>
          <p class="card__subheading">{{ cardData.subtitle }}</p>
        </div>
        <div class='card__bottom'>
          <p class="card__description">{{ cardData.info }}</p>

          <button type="button" @click="goToProductModel(cardData)" class="btn">
            <span class="button-text">Entdecken</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuCard',
  props: {
    index:Number,
    isDeepdive:String,
    cardData: {
      heading: String
    }
  },
  methods: {
    async goToProductModel(card_data) {
      let data = {
        uuid: card_data.uuid
      };
      try {
        await this.$http.patch('/user', JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
      let routeTo = '';
      if (card_data.uuid == 'c282f220-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdiveslido';
      } else if (card_data.uuid == 'c15dd310-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdive';
      } else if (card_data.uuid == 'c20c1470-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdiveakademie';
      } else if (card_data.krpano_path) {
        routeTo = `/krpano/${card_data.uuid}`;
      }
      this.$router.push(routeTo);
    }
  },
  computed: {
    isVisitor() {
      if (this.$store.state.user.roles) {
        return !this.$store.state.user.roles.includes('exhibitor');
      } else return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  background: white;
  width: 100%;
  text-align: left;
  text-transform: none;
  border-radius: 1px;
  margin: 0;
  margin-top: auto;
  padding: 7px 14px !important;
  align-self: flex-end;

  &:hover {
    background-color: $secondaryColor;
  }
}

.user_count {
  height: 35px;
  width: 42px;
  .counter_number {
    color: white;
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -35px;
  right: 15px;
}

.card {
  position: relative;

  &__wrapper {
    max-width: 350px;
    min-width: 250px;
    background-color: rgba(80, 80, 80, 0.8);
    margin: 30px;
    min-height: 70vh;
    z-index: 2;

    @include respond(laptop){
      max-width: 280px;
      margin: 20px;
      height: 65vh;
    }
  }

  &__img {
    width: 100%;

    &--wrapper{
      width: 100%;
      overflow: hidden;

      @include respond(laptop){
        height: 40%;
      }
    }
  }

  &__content {
    position: relative;
    padding: 15px 40px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-between;
    max-height: 53%;

    @include respond(laptop){
      padding: 15px 25px;
    }
  }

  &__header{
    height: 150px;
    
  }
  &__bottom{
    position: relative;
  }

  &__counter {
    font-size: 12px;
    margin-bottom: 0%;
    color: white;
    text-transform: uppercase;

    @include respond(laptop){
      font-size: 10px;

    }
  }


  &__heading {
    text-align: left;
    font-size: 1.6rem;
    line-height: 34px;
    font-weight: Light;
    letter-spacing: 0.56px;
    color: $secondaryColor;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
    padding: 5px 0;

    @include respond(laptop){
      font-size: 20px;
      line-height: 26px;
      padding-top: 5px;
    }
  }

  &__subheading {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: medium;
    letter-spacing: 0.7px;
    color: rgba(188, 188, 188, 1);
    text-transform: uppercase;
    opacity: 1;

    // margin-bottom: 35px;
    height: 50px;

    @include respond(laptop){
      font-size: 12px;
      margin-bottom: 25px;
    }
  }

  &__description {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.49px;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    min-height:75px;
    padding-top:10px;

    @include respond(laptop-small){
      font-size: 12px;
      // height: 80px;
    }

    @include respond(laptop){
      height: 80px;
    }
  }
}
</style>
