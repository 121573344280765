<template>
  <div @click="goToProductModel(cardData)" class="card__wrapper">
    <div class="img_wrapper">
      <img class="card__img" :src="cardData.logo_path" alt />
    </div>
    <div class="card__content">
      <h2 class="card__heading">{{cardData.title}}</h2>
      <!-- <button type="button" class="btn">
        <span class="button-text">Entdecken</span>
      </button>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      heading: String
    }
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/images/' + pic);
    },
    async goToProductModel(card_data) {
      let data = {
        uuid: card_data.uuid
      };
      try {
        await this.$http.patch('/user', JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
      let routeTo = '';
      if (card_data.uuid == 'c282f220-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdiveslido';
      } else if (card_data.uuid == 'c15dd310-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdive';
      } else if (card_data.uuid == 'c20c1470-ab01-11ea-8fdc-4f727217e998') {
        routeTo = '/deepdiveakademie';
      } else if (card_data.krpano_path) {
        routeTo = `/krpano/${card_data.uuid}`;
      }
      this.$router.push(routeTo);
    }
  },

  name: 'MenuCard'
};
</script>

<style lang="scss" scoped>
.btn {
  background: white;
}

.img_wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  .card__img {
    width: 100%;
    height: auto;
  }
}
.card {
  &__wrapper {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 220px;
    max-height: 22vh;
  }

  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    padding: 50px 40px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }

  &__heading {
    text-align: left;
    font-size: 20px;
    line-height: 30px;

    font-weight: Light;
    letter-spacing: 0.2px;
    color: rgba(247, 234, 164, 1);
    text-transform: uppercase;
    opacity: 1;
  }
}
</style>
