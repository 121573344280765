<template>
  <div class="row">
    <transition name="fade" mode="out-in">
      <div v-if="showOverview" class="menu-wrapper" key="overview">
        <div class="overview_grid" key="overview">
          <MenuOverviewCard v-for="card in cards" :cardData="card" :key="card.id" />
        </div>
      </div>
      <!-- <div v-else>
      <transition-group class="menu__grid" name="fade">
        <MenuCard v-for="card in cardSubset" :cardData="card" :key="card.id" />
      </transition-group>
      </div>-->

      <div class="menu-wrapper" v-else key="menu">
        <div key="wrap">
          <transition name="fade" mode="out-in">
            <div v-if="active ===1" class="three-row" key="1">
              <MenuCard :cardData="cards[0]" :index="1"/>
              <MenuCard :cardData="cards[1]" :index="2"/>
              <MenuCard :cardData="cards[2]" :index="3"/>
            </div>
            <div v-if="active ===2" class="three-row" key="2">
              <MenuCard :cardData="cards[3]" :index="4"/>
              <MenuCard :cardData="cards[4]" :index="5"/>
              <MenuCard :cardData="cards[5]" :index="6"/>
            </div>
            <div v-if="active ===3" class="three-row" key="3">
              <MenuCard :cardData="cards[6]" :index="7"/>
              <MenuCard :cardData="cards[7]" :index="8"/>
              <MenuCard :cardData="cards[8]" :index="9"/>
            </div>
            <div v-if="active ===4" class="three-row" key="4">
              <MenuCard :cardData="cards[9]" :isDeepdive="'deepdive'" :index="10"/>
              <MenuCard :cardData="cards[10]" :isDeepdive="'deepdiveakademie'" :index="11"/>
              <MenuCard :cardData="cards[11]" :isDeepdive="'deepdiveslido'" :index="12"/>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="menu__sidenav">
      <div>
        <div @click="menuToggle()" class="menu__overview">
          <div :class="['square__overview', {active: showOverview}]"></div>
          <div :class="['square__overview', {active: showOverview}]"></div>
          <div :class="['square__overview', {active: showOverview}]"></div>
          <div :class="['square__overview', {active: showOverview}]"></div>
        </div>
      </div>
      <div v-for="i in cards.length/3" :key="i" class="menu__point">
        <span @click="paginateMenu(i)" :class="['square', {active: active === i && !showOverview}]"></span>
        <span v-if="i != cards.length/3" class="line"></span>
      </div>
    </div>
    <div v-if="!showOverview && !isVisitor" class="user_count">
      <img class="card__img" src="~/assets/images/user_counter.png" />
      <p>Aktuelle Besucher in dieser Sektion</p>
    </div>
  </div>
</template>

<script>
import MenuCard from 'components/shared/MenuCard.vue';
import MenuOverviewCard from 'components/shared/MenuOverviewCard.vue';
import _ from 'lodash';

export default {
  components: {
    MenuCard,
    MenuOverviewCard
  },
  data: () => ({
    pagination: { start: 0, end: 3 },
    start: 0,
    end: 3,
    showOverview: false,
    cards: [
      {
        id: 1,
        title: 'Empfang',
        krpano_path:'1',
        user_count: 0,
        subtitle: 'Ihr Treffpunkt mit dem Häfele Fachberater.',
        kr_pano: 'https://app01.b-rex.de/api/data/booth/Haefele_menu10.png',
        info:
          'Außerdem erfahren Sie hier, wie Sie auf dieser digitalen Plattform intuitiv navigieren können, und welche Funktionen Ihnen zur Verfügung stehen.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu1.png',
        uuid: '5a23e400-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 2,
        title: 'Themenwelt Küche',
        krpano_path:'1',
        subtitle: 'Küche ganzheitlich gedacht.',
        info:
          'Alles für die Küche. Alles aus einer Hand. Komplette Küchensortimente in zwei Varianten.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu2.png',
        uuid: '65b63b60-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 3,
        title: 'Neue Küchen-Ideen',
        krpano_path:'1',
        subtitle: 'Mehr Effizienz. Mehr Möglichkeiten.',
        info:
          'Ein innovativer Klappenbeschlag, eine multifunktionale Rückwand und eine zeitsparende Montagehilfe.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu3.png',
        uuid: '6a926f00-ab01-11ea-8fdc-4f727217e998'
      },

      {
        user_count: 0,
        id: 4,
        title: 'Möbelbau weiter gedacht',
        krpano_path:'1',
        subtitle: 'Modulare Schranksysteme. Schnell geplant. Schnell montiert.',
        info:
          'Design trifft Funktion: Rahmensystem Versatile und Schrank-Innenausstattungssystem Dresscode. Entwickelt von Häfele.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu4.png',
        uuid: '6edfd020-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 5,
        title: 'Schubkasten-arena',
        krpano_path:'1',
        subtitle:
          'Häfele Zargenführungssysteme und Komplettschubkästen in Variationen.',
        info:
          'Häfele Zargenführungssysteme und Komplettschubkästen in Variationen.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu5.png',
        uuid: '74196600-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 6,
        title: 'Geschäftsfeld Akustik',
        krpano_path:'1',
        subtitle: 'Neue Möglichkeiten für das Handwerk.',
        info:
          'Sortimente und Systeme zur Schalldämpfung im Büro, in der Gastronomie, in Schulen etc.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu6.png',
        uuid: '776c88a0-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 7,
        title: 'Licht im Möbel',
        krpano_path:'1',
        subtitle: 'Licht einfach planen, vernetzen, steuern.',
        info:
          'Das neue Häfele Lichtsystem Loox5 und Häfele Connect: Der intelligente Weg zum digitalen Wohnen.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu7.png',
        uuid: '7efc6a90-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 8,
        title: 'Schnell, schön, praktisch',
        krpano_path:'1',
        subtitle:
          'Inspirierende und funktionale Häfele Entwicklungen für die Partner im Handwerk.',
        info:
          'Vom werkzeuglos einsetzbaren Verbinder bis zum batteriebetriebenen Möbelschloss.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu8.png',
        uuid: '8841ebc0-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 9,
        title: 'Design-Akzente',
        krpano_path:'1',
        subtitle: 'Möbelgriffe aus dem Häfele Design-Studio.',
        info:
          'Die aktuelle Möbelgriffe-Kollektion designed by Häfele. Plus ein exklusives Programm zeitloser Design-Klassiker.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu9.png',
        uuid: '8ee17a40-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 10,
        title: 'Häfele „YES!“',
        subtitle: 'Die Berater-App für fotorealistische Planung in Echtzeit.',
        info:
          'Mehr Kundenbegeisterung. Schnellerer Geschäftsabschluss. Effizienter Workflow. Einfache Bedienung.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu10.png',
        isDeepdive:true,
        uuid: 'c15dd310-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 11,
        title: 'Häfele Akademie',
        subtitle:
          'Seminare speziell für Tischler/Schreiner zum Thema Licht und Akustik.',
        info:
          'Die unterschiedlichen Schulungsmodule sind für alle Beteiligten im Handwerk geeignet. Vom Geschäftsführer bis zum Montage-Team.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu11.png',
        isDeepdive:true,
        uuid: 'c20c1470-ab01-11ea-8fdc-4f727217e998'
      },
      {
        user_count: 0,
        id: 12,
        title: 'Schieben x 4',
        subtitle:
          'Schiebebeschläge Slido Furniture: Für jede Bewegung eine Lösung.',
        info:
          'Mit den Produktlinien FOLD, FLUSH, LINE und PARK deckt Häfele Slido jede Bewegungsart im Bereich Möbelschiebe-Beschläge umfassend ab.',
        logo_path: 'https://app01.b-rex.de/api/data/booth/Haefele_menu12.png',
        isDeepdive:true,
        uuid: 'c282f220-ab01-11ea-8fdc-4f727217e998'
      }
    ]
  }),
  computed: {
    active() {
      return this.$store.state.activeMenu;
    },
    isVisitor() {
      if (this.$store.state.user.roles) {
        return !this.$store.state.user.roles.includes('exhibitor');
      } else return false;
    },
    cardSubset() {
      return this.cards.slice(this.start, this.end);
    }
  },
  mounted() {
    //TODO: axios /exhibition
    this.getCounter();
    this.eventListener = _.debounce(this.handleScroll, 100, {
      leading: true,
      trailing: false
    });
    window.addEventListener('wheel', this.eventListener);
  },
  destroyed() {
    window.removeEventListener('wheel', this.eventListener);
  },
  methods: {
    async getCounter() {
      try {
        if (!this.isVisitor) {
          let exhibition = await this.$http.get('/exhibition');
          this.cards = exhibition.data.booths;
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleScroll(event) {
      if (this.$store.state.popup) {
        return;
      }
      if (this.active === this.cards.length / 3 && event.deltaY > 0) {
        this.start = 0;
        this.end = 3;
        this.$store.state.activeMenu = 1;
      } else if (this.active === 1 && event.deltaY < 0) {
        this.end = this.cards.length;
        this.start = this.end - 3;
        this.$store.state.activeMenu = this.cards.length / 3;
      } else if (event.deltaY > 0) {
        this.start += 3;
        this.end += 3;
        this.$store.state.activeMenu++;
      } else if (event.deltaY < 0) {
        this.start -= 3;
        this.end -= 3;
        this.$store.state.activeMenu--;
      }
    },
    paginateMenu(i) {
      this.showOverview = false;
      this.$store.state.activeMenu = i;
      if (i === 1 || i === 5) {
        this.$store.state.activeMenu = 1;
        this.start = 0;
        this.end = 3;
      } else {
        this.start = i * 2 + 1;
        this.end = this.start + 3;
      }
    },
    menuToggle() {
      this.showOverview = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  width: 100vw;
  overflow: hidden;
}

.user_count {
  display: flex;
  position: absolute;
  right: 72px;
  bottom: 55px;
  img {
    width: 35px;
    height: 60px;

    @include respond(laptop) {
      width: 20px;
      height: 35px;
    }
  }
  p {
    color: white;
    margin-left: 5px;
    font-size: 12px;
    width: 90px;

    @include respond(laptop-small) {
      font-size: 9px;
      width: 80px;
    }
  }
}

.three-row {
  display: flex;
  margin: auto;
  padding: auto 5rem;
}

.menu-wrapper {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(laptop) {
    padding-top: 20px;
    align-items: flex-start;
  }
}

.menu__grid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
  flex-direction: row;
  /* justify-content: center; */
}

.overview_grid {
  height: 70vh;
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 20vw));
}

.menu {
  &__point {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__sidenav {
    padding: 0 40px 0 0;
    margin-left: -50px;
    .line {
      height: 100px;
      width: 2px;
      background: white;
    }
    .square {
      margin: 10px 0;
      height: 20px;
      width: 20px;
      border: 1px solid white;
      cursor: pointer;

      &__overview {
        height: 9px;
        width: 9px;
        border: 1px solid white;
        cursor: pointer;
      }
    }
    .active {
      background: white;
    }
  }

  &__overview {
    width: 20px;
    height: 20px;
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;

    padding-bottom: 100px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -moz-transform: scale(1); /* Firefox */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1);
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}

.fade-leave-to {
  opacity: 0;
  transform: scale(2) translate(0px, 0px);
  -ms-transform: scale(2) translate(0px, 0px); /* IE 9 */
  -moz-transform: scale(2) translate(0px, 0px); /* Firefox */
  -webkit-transform: scale(2) translate(0px, 0px); /* Safari and Chrome */
  -o-transform: scale(2) translate(0px, 0px);
}

.scroll-down {
  position: absolute;
  bottom: 10%;
  left: 49%;
  transform: translate(-70%, -50%);
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}
.scroll-down span:nth-child(2) {
  animation-delay: -0.2s;
}
.scroll-down span:nth-child(3) {
  animation-delay: -0.4s;
}
.scroll-down span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
</style>
